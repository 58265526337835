<template>
  <div>
    <b-modal v-model="modalShow" size="xl" no-close-on-backdrop no-close-on-esc>
      <template #modal-header>Select Item</template>
      <b-tabs class="tabs-panel">
        <b-tab @click="handleChangeTabs(1)" title="Product" class="text-nowrap">
          <div class="filter-panel p-3">
            <div class="category-panel" v-if="type == 1">
              <b-row>
                <b-col>
                  <InputSelect
                    title="Level Category 1"
                    name="type"
                    isRequired
                    v-model="filter.ah1"
                    v-bind:options="categoryList.category1"
                    valueField="ah_id"
                    textField="ah_name"
                    @onDataChange="selectCategory($event, 1)"
                  />
                </b-col>
                <b-col>
                  <InputSelect
                    title="Level Category 2"
                    name="type"
                    isRequired
                    v-model="filter.ah2"
                    v-bind:options="categoryList.category2"
                    valueField="ah_id"
                    textField="ah_name"
                    @onDataChange="selectCategory($event, 2)"
                  />
                </b-col>
                <b-col>
                  <InputSelect
                    title="Level Category 3"
                    name="type"
                    isRequired
                    v-model="filter.ah3"
                    v-bind:options="categoryList.category3"
                    valueField="ah_id"
                    textField="ah_name"
                    @onDataChange="selectCategory($event, 3)"
                  />
                </b-col>
                <b-col>
                  <InputSelect
                    title="Level Category 4"
                    name="type"
                    isRequired
                    v-model="filter.ah4"
                    v-bind:options="categoryList.category4"
                    valueField="ah_id"
                    textField="ah_name"
                    @onDataChange="selectCategory($event, 4)"
                  />
                </b-col>
              </b-row>
              <b-row class="mt-3">
                <b-col md="6">
                  <b-input-group>
                    <b-form-input
                      class="search-bar"
                      @keyup.enter="onSubmitFilter"
                      placeholder="Search Privilege Name, Promotion Code"
                      v-model="filter.search"
                    >
                    </b-form-input>
                    <b-input-group-append is-text>
                      <b-iconstack
                        font-scale="2"
                        type="submit"
                        @click="onSubmitFilter"
                      >
                        <b-icon
                          stacked
                          icon="search"
                          scale="0.5"
                          variant="grey"
                        ></b-icon>
                      </b-iconstack>
                    </b-input-group-append>
                  </b-input-group>
                </b-col>
              </b-row>
            </div>
          </div>
          <b-row class="p-3">
            <b-col sm="12">
              <b-table
                responsive
                striped
                hover
                :fields="computedFieldsSelectProduct"
                :items="productList"
                :busy="isBusy"
                class="table-product-list"
                show-empty
                empty-text="No matching records found"
              >
                <!-- <template v-slot:head(id)="">
                  <b-form-group class="cb-table">
                    <b-form-checkbox
                      v-model="selectAllProduct"
                      @change="selectAllTable"
                    ></b-form-checkbox>
                  </b-form-group>
                </template> -->
                <template v-slot:cell(id)="data">
                  <b-form-group class="cb-table">
                    <b-form-checkbox
                      :value="data.item.id"
                      v-model="filter.productIdList"
                      @change="selectProductTable(1, data.item.id, $event)"
                      :disabled="handleDisabledItems(data.item.id)"
                    ></b-form-checkbox>
                  </b-form-group>
                </template>
                <template v-slot:cell(imge_url)="data">
                  <div class="position-relative picture-text pic-table">
                    <div
                      v-if="data.item.image_url"
                      class="square-box b-contain image"
                      v-bind:style="{
                        'background-image': 'url(' + data.item.image_url + ')',
                      }"
                    ></div>
                    <div
                      v-else-if="data.item.imageUrl"
                      class="square-box b-contain image"
                      v-bind:style="{
                        'background-image': 'url(' + data.item.imageUrl + ')',
                      }"
                    ></div>
                    <div v-else>
                      <div
                        class="square-box b-contain image"
                        v-bind:style="{
                          'background-image': 'url(' + default_image + ')',
                        }"
                      ></div>
                    </div>
                  </div>
                </template>
                <template v-slot:cell(name)="data">
                  <div class="text-left">
                    <p class="m-0 text-black" v-if="data.item.name">
                      {{ data.item.name }}
                    </p>
                    <div v-if="type != 1">
                      {{ data.item.short_description }}
                    </div>
                    <div v-else>
                      {{ data.item.article_no }}
                      <span class="article-type">{{
                        data.item.article_type
                      }}</span>
                    </div>
                  </div>
                </template>
                <template v-slot:cell(barcode)="data">
                  <p class="m-0" v-if="data.item.barcode">
                    {{ data.item.barcode }}
                  </p>
                  <p v-else class="m-0">-</p>
                </template>

                <template v-slot:cell(price)="data">
                  <p class="m-0 price-text" v-if="data.item.price">
                    {{ data.item.price | numeral("0,0.00") }}
                  </p>
                  <p v-else class="m-0 price-text">-</p>
                </template>

                <template v-slot:cell(coupon)="data">
                  <p class="m-0 price-text" v-if="data.item.coupon">
                    {{ data.item.coupon }}
                  </p>
                  <p v-else class="m-0 price-text">-</p>
                </template>
                <template v-slot:cell(startDate)="data">
                  <p
                    class="m-0 price-text"
                    v-if="data.item.startDate || data.item.validFrom"
                  >
                    {{
                      $moment(
                        data.item.startDate || data.item.validFrom
                      ).format("DD/MM/YYYY")
                    }}
                    <br />
                    {{
                      $moment(
                        data.item.startDate || data.item.validFrom
                      ).format("HH:mm:ss")
                    }}
                  </p>
                  <p v-else class="m-0 price-text">-</p>
                </template>
                <template v-slot:cell(endDate)="data">
                  <p
                    class="m-0 price-text"
                    v-if="data.item.endDate || data.item.validTo"
                  >
                    {{
                      $moment(data.item.endDate || data.item.validTo).format(
                        "DD/MM/YYYY"
                      )
                    }}
                    <br />
                    {{
                      $moment(data.item.endDate || data.item.validTo).format(
                        "HH:mm:ss"
                      )
                    }}
                  </p>
                  <p v-else class="m-0 price-text">-</p>
                </template>

                <template v-slot:table-busy>
                  <div class="text-center text-black my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-2">Loading...</strong>
                  </div>
                </template>
              </b-table>
            </b-col>
            <b-col sm="4">
              <p class="mb-0 p-gray">
                Showing {{ rows > 0 ? filter.start + 1 : 0 }} to
                {{
                  filter.start + filter.length >= rows
                    ? rows
                    : filter.start + filter.length
                }}
                of {{ rows | numeral("0,0") }} entries
              </p>
            </b-col>
            <b-col sm="8">
              <b-row>
                <b-col class="form-inline justify-content-end">
                  <div>
                    <b-pagination
                      v-model="filter.pageNo"
                      :total-rows="rows"
                      :per-page="filter.length"
                      @change="pagination"
                      first-text="First"
                      prev-text="Prev"
                      next-text="Next"
                      last-text="Last"
                      class="m-md-0"
                    ></b-pagination>
                  </div>
                  <div class="ml-1">
                    <b-form-select
                      v-model="filter.length"
                      @change="hanndleChangePerpage"
                      :options="pageOptions"
                    ></b-form-select>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab @click="handleChangeTabs(3)" title="Coupon" class="text-nowrap">
          <b-row class="p-3">
            <b-col sm="12">
              <b-table
                responsive
                striped
                hover
                :fields="computedFieldsSelectProduct"
                :items="productList"
                :busy="isBusy"
                class="table-product-list"
                show-empty
                empty-text="No matching records found"
              >
                <template v-slot:cell(id)="data">
                  <b-form-group class="cb-table">
                    <b-form-checkbox
                      :value="data.item.id"
                      :checked="data.item.isCheck == 1 ? data.item.id : false"
                      @change="
                        selectProductTable(
                          3,
                          data.item.id,
                          $event,
                          data.item.coupon_id
                        )
                      "
                    ></b-form-checkbox>
                  </b-form-group>
                </template>
                <template v-slot:cell(imge_url)="data">
                  <div class="position-relative picture-text pic-table">
                    <div
                      v-if="data.item.image_url"
                      class="square-box b-contain image"
                      v-bind:style="{
                        'background-image': 'url(' + data.item.image_url + ')',
                      }"
                    ></div>
                    <div
                      v-else-if="data.item.imageUrl"
                      class="square-box b-contain image"
                      v-bind:style="{
                        'background-image': 'url(' + data.item.imageUrl + ')',
                      }"
                    ></div>
                    <div v-else>
                      <div
                        class="square-box b-contain image"
                        v-bind:style="{
                          'background-image': 'url(' + default_image + ')',
                        }"
                      ></div>
                    </div>
                  </div>
                </template>
                <template v-slot:cell(name)="data">
                  <div class="text-left">
                    <p class="m-0 text-black" v-if="data.item.name">
                      {{ data.item.name }}
                    </p>
                    <div v-if="type != 1">
                      {{ data.item.short_description }}
                    </div>
                    <div v-else>
                      {{ data.item.article_no }}
                      <span class="article-type">{{
                        data.item.article_type
                      }}</span>
                    </div>
                  </div>
                </template>
                <template v-slot:cell(barcode)="data">
                  <p class="m-0" v-if="data.item.barcode">
                    {{ data.item.barcode }}
                  </p>
                  <p v-else class="m-0">-</p>
                </template>

                <template v-slot:cell(price)="data">
                  <p class="m-0 price-text" v-if="data.item.price">
                    {{ data.item.price | numeral("0,0.00") }}
                  </p>
                  <p v-else class="m-0 price-text">-</p>
                </template>
                <template v-slot:cell(discount)="data">
                  <p class="m-0 price-text">
                    {{ data.item.promotion_value | numeral("0,0") }}
                    {{ data.item.promotion_discount_type_id == 2 ? "฿" : "%" }}
                  </p>
                  <!-- <p v-else class="m-0 price-text">-</p> -->
                </template>
                <template v-slot:cell(coupon)="data">
                  <p class="m-0 price-text" v-if="data.item.coupon">
                    {{ data.item.coupon }}
                  </p>
                  <p v-else class="m-0 price-text">-</p>
                </template>
                <template v-slot:cell(startDate)="data">
                  <p
                    class="m-0 price-text"
                    v-if="data.item.startDate || data.item.validFrom"
                  >
                    {{
                      $moment(
                        data.item.startDate || data.item.validFrom
                      ).format("DD/MM/YYYY")
                    }}
                    <br />
                    {{
                      $moment(
                        data.item.startDate || data.item.validFrom
                      ).format("HH:mm:ss")
                    }}
                  </p>
                  <p v-else class="m-0 price-text">-</p>
                </template>
                <template v-slot:cell(endDate)="data">
                  <p
                    class="m-0 price-text"
                    v-if="data.item.endDate || data.item.validTo"
                  >
                    {{
                      $moment(data.item.endDate || data.item.validTo).format(
                        "DD/MM/YYYY"
                      )
                    }}
                    <br />
                    {{
                      $moment(data.item.endDate || data.item.validTo).format(
                        "HH:mm:ss"
                      )
                    }}
                  </p>
                  <p v-else class="m-0 price-text">-</p>
                </template>

                <template v-slot:table-busy>
                  <div class="text-center text-black my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-2">Loading...</strong>
                  </div>
                </template>
              </b-table>
            </b-col>
            <b-col sm="4">
              <p class="mb-0 p-gray">
                Showing {{ rows > 0 ? filter.start + 1 : 0 }} to
                {{
                  filter.start + filter.length >= rows
                    ? rows
                    : filter.start + filter.length
                }}
                of {{ rows }} entries
              </p>
            </b-col>
            <b-col sm="8">
              <b-row>
                <b-col class="form-inline justify-content-end">
                  <div>
                    <b-pagination
                      v-model="filter.pageNo"
                      :total-rows="rows"
                      :per-page="filter.length"
                      @change="pagination"
                      first-text="First"
                      prev-text="Prev"
                      next-text="Next"
                      last-text="Last"
                      class="m-md-0"
                    ></b-pagination>
                  </div>
                  <div class="ml-1">
                    <b-form-select
                      v-model="filter.length"
                      @change="hanndleChangePerpage"
                      :options="pageOptions"
                    ></b-form-select>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>

      <template #modal-footer>
        <b-row>
          <b-col class="text-left">
            <b-button @click.prevent="hide" class="border-btn">Cancel</b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button class="submit-btn" @click.prevent="submitProduct"
              >Select Items</b-button
            >
          </b-col>
        </b-row>
      </template>
    </b-modal>
  </div>
</template>

<script>
import InputSelect from "@/components/inputs/InputSelect";

export default {
  components: {
    InputSelect,
  },
  props: {
    form: { required: true },
    freezeObj: { required: true },
  },
  data() {
    return {
      modalShow: false,
      isBusy: false,
      fieldsSelectProduct: [
        {
          key: "id",
          label: "",
          class: "text-nowrap",
          type: 1,
        },
        {
          key: "imge_url",
          label: "Image",
          class: "text-nowrap",
          type: 1,
        },
        {
          key: "name",
          label: "Name / Detail",
          class: "text-nowrap",
          type: 1,
        },
        {
          key: "barcode",
          label: "SKU",
          class: "text-nowrap",
          type: 2,
        },
        {
          key: "price",
          label: "Price",
          class: "text-nowrap",
          type: 2,
        },
        {
          key: "discount",
          label: "Discount(%)",
          class: "text-nowrap",
          type: 4,
        },
        {
          key: "prefix",
          label: "Coupon Code",
          class: "text-nowrap",
          type: 3,
        },
        {
          key: "startDate",
          label: "Valid From",
          class: "text-nowrap",
          type: 3,
        },
        {
          key: "endDate",
          label: "Valid To",
          class: "text-nowrap",
          type: 3,
        },
      ],
      productList: [],
      allListId: [],
      pageOptions: [
        { value: 5, text: "5 / page" },
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      rows: 0,
      filter: {
        search: "",
        pageNo: 1,
        start: 0,
        length: 5,
        ah1: 0,
        ah2: 0,
        ah3: 0,
        ah4: 0,
        productIdList: [],
      },

      productSelect: { 1: [], 3: [] },
      categoryList: {
        category1: [
          {
            ah_id: 0,
            ah_name: "All",
          },
        ],
        category2: [
          {
            ah_id: 0,
            ah_name: "All",
          },
        ],
        category3: [
          {
            ah_id: 0,
            ah_name: "All",
          },
        ],
        category4: [
          {
            ah_id: 0,
            ah_name: "All",
          },
        ],
      },
      selectAllProduct: false,
      type: 1,
    };
  },
  computed: {
    computedFieldsSelectProduct() {
      if (this.type == 1) {
        return this.fieldsSelectProduct.filter(
          (field) => field.type != 3 && field.type != 4
        );
      } else if (this.type == 2) {
        return this.fieldsSelectProduct.filter(
          (field) => field.type != 2 && field.type != 4
        );
      } else {
        return this.fieldsSelectProduct.filter((field) => field.type != 2);
      }
    },
  },
  methods: {
    handleChangeTabs(type) {
      this.filter.pageNo = 1;
      this.filter.length = 5;
      this.getProductList(type);
    },
    async show(type, select) {
      this.type = type;
      this.filter.productIdList = select;
      this.modalShow = true;
      await this.getProductList();
      this.selectAllProduct =
        this.productList.length == this.allListId.length ? true : false;
    },
    hide() {
      this.filter = {
        search: "",
        pageNo: 1,
        start: 0,
        length: 5,
        ah1: 0,
        ah2: 0,
        ah3: 0,
        ah4: 0,
        productIdList: [...this.filter.productIdList],
      };
      this.categoryList = {
        category1: [
          {
            ah_id: 0,
            ah_name: "All",
          },
        ],
        category2: [
          {
            ah_id: 0,
            ah_name: "All",
          },
        ],
        category3: [
          {
            ah_id: 0,
            ah_name: "All",
          },
        ],
        category4: [
          {
            ah_id: 0,
            ah_name: "All",
          },
        ],
      };
      this.selectAllProduct = false;
      this.modalShow = false;
    },
    onSubmitFilter() {
      this.filter.pageNo = 1;
      this.filter.start = 0;
      this.filter.length = 5;
      this.getProductList();
    },

    pagination(Page) {
      this.filter.pageNo = Page;
      this.getProductList();
    },
    hanndleChangePerpage(value) {
      this.filter.pageNo = 1;
      this.filter.length = value;
      this.getProductList();
    },
    async getProductList(type = this.type) {
      let item = [];
      let rows = 0;
      let allId = [];
      let data = {};
      this.type = type;
      this.isBusy = true;
      this.filter.start = (this.filter.pageNo - 1) * this.filter.length;
      if (type == 1) {
        await this.$store.dispatch("getListProduct", this.filter);
        data = this.$store.state.redeem.productList;
        item = data.detail.product_list;
        rows = data.detail.total_product;
        allId = data.detail.id_all;
      } else if (type == 2) {
      } else {
        let body = {
          id: 1,
          item_select: this.filter.productIdList,
          page: this.filter.pageNo,
          reference_id: 0,
          search: "",
          show_only_select: 0,
          take: this.filter.length,
        };
        await this.$store.dispatch("ActionGetPrivilegeCondition", body);
        data = await this.$store.state.modulePrivilegeCode
          .stateGetPrivilegeCondition;
        item = data.detail.detail;
        rows = data.detail.total_count;
      }
      this.productList = item;
      this.rows = rows;
      this.allListId = allId;
      if (type == 1) {
        for (let i = 1; i <= 4; i++) {
          if (this.categoryList[`category${i}`].length == 1) {
            this.categoryList[`category${i}`] = data.detail[`list_ah${i}`]
              ? this.categoryList[`category${i}`].concat(
                  data.detail[`list_ah${i}`]
                )
              : this.categoryList[`category${i}`];
          }
        }
      }

      this.isBusy = false;
    },
    clearCategory(id, index) {
      this.filter.categories.forEach((element, cindex) => {
        if (cindex > index) {
          this.categoryList[`category${cindex}`] = [
            {
              value: 0,
              text: "All",
            },
          ];
          this.filter.categories[cindex] = 0;
        } else if (cindex == index) {
          this.filter.categories[cindex] = 0;
        }
      });
    },
    onCateGoryChange(id, index) {
      this.clearCategory(id, index);
      this.getCategoryList(id, index + 1);
      this.filter.categories[index - 1] = id;
      this.getProductList();
    },
    getCategoryList(id, index) {
      this.axios.get(`/productinventory/category/${id}`).then((data) => {
        if (data.detail) {
          data.detail.category.forEach((l) => {
            this.categoryList[`category${index}`].push({
              value: l.categoryId,
              text: l.categoryName,
            });
          });
        } else {
          this.categoryList[`category${index}`] = [];
          this.categoryList[`category${index}`].push({
            value: 0,
            text: "All",
          });
          this.filter.categories[index] = 0;
        }
      });
    },
    selectAllTable(value) {
      if (value) {
        this.filter.productIdList = [];
        this.filter.productIdList = this.allListId;
      } else {
        this.filter.productIdList = [];
      }
    },
    selectProductTable(type, value, values, coupon_id) {
      if (type == 3) {
        let index = this.filter.productIdList.indexOf(value);
        let indexCoupon = this.filter.productIdList.indexOf(coupon_id);

        if (index != -1) {
          this.filter.productIdList.splice(index, 1);
        } else {
          this.filter.productIdList.push(value);
        }
      }
      if (this.freezeObj.find((el) => el.id == value))
        if (this.productSelect[type].indexOf(value) != -1)
          return this.productSelect[type].splice(
            this.productSelect[type].indexOf(value),
            1
          );

      this.productSelect[type].push(value);

      this.selectAllProduct =
        this.allListId.length == values.length ? true : false;
    },
    selectCategory(value, num) {
      this.filter[`ah${num}`] = value;
      for (let i = 1; i <= 4; i++) {
        if (i > num) {
          this.categoryList[`category${i}`] = [
            {
              ah_id: 0,
              ah_name: "All",
            },
          ];
          this.filter[`ah${i}`] = 0;
        }
      }
      this.getProductList();
    },
    async getKeyByValue(object, value) {
      let keys = Object.keys(object);
      for (const key of keys) {
        const val = object[key].find((el) => el == value);
        if (val) return Number(key);
      }
    },
    async submitProduct() {
      let product = [];

      for (const e of this.filter.productIdList) {
        product.push({
          id: e,
          point_used: 0,
          is_theme: 0,
          is_upcoming_reward: 0,
          active: 1,
          sort_order: 0,
          redeem_type_id: await this.getKeyByValue(this.productSelect, e),
        });
      }

      this.$emit("submitProduct", product);
      this.hide();
    },
    handleDisabledItems(id) {
      let find = this.form.items.selectProduct.find((el) => el.id == id);
      if (find) {
        return find.is_redeem ? true : false;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .input-group-text {
  background-color: #fff;
  padding: 0 0.75rem;
}
.input-search {
  color: #404040 !important;
  border: 1px solid #d8dbe0 !important;
  border-radius: 6px !important;
  padding: 9px 10px !important;
  height: 41px;
}
.btn-collapse {
  color: #333333;
}
.white-color {
  color: #ffffff;
}
.text-gray {
  color: #bcbcbc;
}
.btn-confirm-modal {
  color: #fff;
  border-radius: 0px;
  border-color: var(--primary-color);
  background-color: var(--primary-color);
  width: 30%;
}
.btn-cancel-modal {
  color: #fff;
  background-color: #39424e;
  border-radius: 0px;
  width: 30%;
  border: 1px solid #39424e;
}
::v-deep .btn-select {
  height: 42.4px;
  color: #404040;
  background-color: white;
  border: 1px solid #d8dbe0;
  border-radius: 6px;
  padding: 9px 10px;
  cursor: pointer;
}
::v-deep .btn-select.custom-select-lg {
  height: 55px;
  font-size: 1rem;
}
::v-deep .btn-select:focus {
  border-color: #80c141;
}
::v-deep .btn-select > option:not(:disabled) {
  background-color: white;
  color: #16274a;
}
::v-deep .btn-select > option:checked {
  color: #16274a !important;
}
::v-deep .btn-select:focus {
  box-shadow: unset !important;
}
.btn-select-product {
  width: 150px;
}
.btn-search {
  background-color: var(--primary-color);
  color: #ffffff;
  width: 42.4px;
  height: 42.4px;
  padding: 6px 0px;
  margin-top: 10px !important;
  border-radius: 50px;
  text-align: center;
  font-size: 20px;
  line-height: 1.42857;
}
.search-input {
  height: 42.4px;
  width: 98%;
  border: 1px solid #d8dbe0;
  border-radius: 7px;
}
.btn-table-select {
  height: 42.4px;
}

.pic-box {
  padding-top: 23% !important;
}
.cb-table {
  position: relative;
  margin-left: 15px;
  padding-top: 15px;
}
::v-deep .modal-body {
  padding: 0 !important;
}
.image-container {
  width: 230px !important;
}
.text-header {
  color: #000;
  font-size: 18px;
}
.text-gray {
  color: #747474;
}
::v-deep .modal-footer {
  justify-content: space-between;
}
::v-deep img {
  width: 230px !important;
  height: 230px !important;
}
::v-deep .tabs-panel {
  .nav-tabs {
    .nav-item {
      text-align: center;
      min-width: 150px;
    }
  }
}
</style>
